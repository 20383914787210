export default {
  mounted () {
    const self = this;
    const keydownHandler = (e) => {
      // for old browser support as a fallback
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        if (this.onEscapePress) {
          this.onEscapePress();
        } else if (self.onEscapePress) {
          self.onEscapePress();
        } else {
          console.dir(self);
          throw new Error('Component using onEscapePress mixin without a defined (onEscapePress) handler.');
        }
      }
    }
    document.addEventListener('keydown', keydownHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', keydownHandler)
    })
  }
}
